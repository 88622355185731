import React from 'react';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import promise from 'redux-promise';
import thunk from 'redux-thunk';

import { redirect } from '../middleware/admin/redirect';
import reducer from '../reducers';

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk, promise, redirect)));

const makeStore = initialValue => createStore(reducer, initialValue, composeWithDevTools(applyMiddleware(thunk, promise, redirect)));

export default store;

import { Component } from 'react';

const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

const getOrCreateStore = initialState => {
    // Always make a new store if server, otherwise state is shared between requests
    if (typeof window === 'undefined') {
        return makeStore(initialState);
    }

    // Create store if unavailable on the client and set it on the window object
    if (!window[__NEXT_REDUX_STORE__]) {
        window[__NEXT_REDUX_STORE__] = makeStore(initialState);
    }

    return window[__NEXT_REDUX_STORE__];
};

export const getStore = () => {
    return window[__NEXT_REDUX_STORE__];
};

export const withReduxStore = App => {
    return class AppWithRedux extends Component {
        static async getInitialProps(appContext) {
            // Get or Create the store with `undefined` as initialState
            // This allows you to set a custom default initialState
            const store = getOrCreateStore();

            // Provide the store to getInitialProps of pages
            appContext.ctx.store = store;

            return {
                ...App.getInitialProps ? await App.getInitialProps(appContext) : {},
                initialReduxState: store.getState(),
            };
        }

        render() {
            // @ts-ignore
            const { initialReduxState } = this.props;

            // eslint-disable-next-line react/jsx-filename-extension
            return <App {...this.props} store={getOrCreateStore(initialReduxState)} />;
        }
    };
};

const initialState = {
    authUserToken: process.browser && localStorage.getItem('authUserHash'),
    userName: '',
    isAdmin: false,
};

export default function reducers(state = initialState, action) {
    switch (action.type) {
        case 'USER_LOGIN_TOKEN':
            return {
                ...state,
                authUserToken: action.payload,
            };
        case 'USER_UPDATE_DATA':
            return {
                ...state,
                id: action.payload.id,
                userName: action.payload.first_name,
                userLastName: action.payload.last_name,
                userMiddleName: action.payload.middle_name,
                avatar: action.payload.avatar,
                isAdmin: action.payload.is_admin,
                authUserToken: action.payload.authUserToken,
                unreadMessagesCount: action.payload.unread_messages_count,
                type: action.payload.type,
            };
        case 'USER_LOGOUT':
            return {
                ...state,
                userName: '',
                authUserToken: '',
                isAdmin: false,
                id: null,
            };
        case 'SET_UNREAD_MESSAGES_COUNT':
            return {
                ...state,
                unreadMessagesCount: action.payload,
            };
        default:
            return state;
    }
}

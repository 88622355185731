import { handleActions } from 'redux-actions';

import * as actions from '@actions/case-page';
import { caseTabsEnum } from '@constants';

const initialState = {
    caseId: null,
    selectedTabName: caseTabsEnum.commonChat,

    isShouldScroll: false,

    formEditCaseRequest: {
        isProcessing: true,
        error: null,
        response: null,
    },

    caseInfoRequest: {
        isProcessing: true,
        error: null,
        response: null,
    },

    workingGroupRequest: {
        isProcessing: true,
        error: null,
        response: null,
    },
};

const casePage = handleActions(
    {
        [actions.setSelectedCaseId]: (state, { payload }) => ({ ...state, caseId: payload }),
        [actions.setSelectedTabName]: (state, { payload }) => ({ ...state, selectedTabName: payload }),
        [actions.setIsShouldScroll]: (state, { payload }) => ({ ...state, isShouldScroll: payload }),
        [actions.setIsGetCaseInfoRequestProcessing]: (state, { payload }) => ({
            ...state,
            caseInfoRequest: {
                ...state.caseInfoRequest,
                isProcessing: payload,
            },
        }),
        [actions.setGetCaseInfoRequestError]: (state, { payload }) => ({
            ...state,
            caseInfoRequest: {
                ...state.caseInfoRequest,
                error: payload,
            },
        }),
        [actions.setCaseInfo]: (state, { payload }) => ({
            ...state,
            caseInfoRequest: {
                ...state.caseInfoRequest,
                response: payload,
            },
        }),
        [actions.setMessagesCounts]: (state, { payload }) => ({
            ...state,
            caseInfoRequest: {
                ...state.caseInfoRequest,
                response: {
                    ...state.caseInfoRequest.response,
                    number_of_messages_in_general_chat: payload.numberOfMessagesInGeneralChat,
                    number_of_messages_in_private_chats: payload.numberOfMessagesInPrivateChats,
                    number_of_unread_messages_in_general_chat: payload.numberOfUnreadMessagesInGeneralChat,
                    number_of_unread_messages_in_private_chats: payload.numberOfUnreadMessagesInPrivateChats,
                },
            },
        }),
        [actions.setIsGetWorkingGroupRequestProcessing]: (state, { payload }) => ({
            ...state,
            workingGroupRequest: {
                ...state.workingGroupRequest,
                isProcessing: payload,
            },
        }),
        [actions.setGetWorkingGroupRequestError]: (state, { payload }) => ({
            ...state,
            workingGroupRequest: {
                ...state.workingGroupRequest,
                error: payload,
            },
        }),
        [actions.setWorkingGroup]: (state, { payload }) => ({
            ...state,
            workingGroupRequest: {
                ...state.workingGroupRequest,
                response: payload,
            },
        }),
        [actions.addUnreadMessageToCount]: (state, { payload }) => {
            const { currentUserId, message } = payload;

            if (!state?.workingGroupRequest?.response) {
                return state;
            }

            return {
                ...state,
                workingGroupRequest: {
                    ...state.workingGroupRequest,
                    response: state.workingGroupRequest.response.map(specialist => {
                        if (specialist.chat_id === message.legal_case_chat_id) {
                            return {
                                ...specialist,
                                number_of_unread_messages:
                                    currentUserId === message.user.id ? specialist.number_of_unread_messages : specialist.number_of_unread_messages + 1,
                            };
                        }

                        return specialist;
                    }),
                },
            };
        },
        [actions.removeUnreadMessagesFromCount]: (state, { payload }) => {
            const { chatId, readMessagesCount } = payload;

            return {
                ...state,
                workingGroupRequest: {
                    ...state.workingGroupRequest,
                    response: state.workingGroupRequest.response.map(specialist => {
                        if (specialist.chat_id === chatId) {
                            const unreadMessageCount = specialist.number_of_unread_messages - readMessagesCount;

                            return {
                                ...specialist,
                                number_of_unread_messages: unreadMessageCount >= 0 ? unreadMessageCount : 0,
                            };
                        }

                        return specialist;
                    }),
                },
            };
        },
        [actions.setIsGetEditCaseFormRequestProcessing]: (state, { payload }) => ({
            ...state,
            formEditCaseRequest: {
                ...state.formEditCaseRequest,
                isProcessing: payload,
            },
        }),
        [actions.setGetEditCaseFormRequestError]: (state, { payload }) => ({
            ...state,
            formEditCaseRequest: {
                ...state.formEditCaseRequest,
                error: payload,
            },
        }),
        [actions.setEditCaseForm]: (state, { payload }) => ({
            ...state,
            formEditCaseRequest: {
                ...state.formEditCaseRequest,
                response: payload,
            },
        }),
    },
    initialState,
);

export default casePage;

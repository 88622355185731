import * as usersOnlineActions from '@actions/users-online';

const initialState = [];

const usersOnline = (state = initialState, action) => {
    switch (action.type) {
        case usersOnlineActions.SET_USERS_ONLINE: {
            return [...action.payload];
        }
        case usersOnlineActions.SET_USER_ONLINE: {
            return [...state, action.payload];
        }
        case usersOnlineActions.SET_USER_OFFLINE: {
            return state.filter(({ id }) => id !== action.payload.id);
        }
        default:
            return state;
    }
};

export default usersOnline;

const prefix = '@CALENDAR';

export const SET_CALENDAR_VALUES = `${prefix}/SET_CALENDAR_VALUES`;
export const SET_CALENDAR_RESET = `${prefix}/SET_CALENDAR_RESET`;
export const SET_CALENDAR_EVENT_OPEN = `${prefix}/SET_CALENDAR_EVENT_OPEN`;
export const SET_CALENDAR_EVENT_CLOSE = `${prefix}/SET_CALENDAR_EVENT_CLOSE`;
export const SET_CALENDAR_EVENT = `${prefix}/SET_CALENDAR_EVENT`;

export const setCalendarValues = values => ({
    type: SET_CALENDAR_VALUES,
    payload: values,
});

export const setCalendarReset = () => ({
    type: SET_CALENDAR_RESET,
});

export const setCalendarEventOpen = () => ({
    type: SET_CALENDAR_EVENT_OPEN,
    payload: true,
});

export const setCalendarEventClose = () => ({
    type: SET_CALENDAR_EVENT_CLOSE,
    payload: false,
});

export const setCalendarEvent = event => ({
    type: SET_CALENDAR_EVENT,
    payload: event,
});

import * as personalOrdersActions from '@actions/personal-orders';

const initialState = {
    filters: null,
    currentPage: 0,

    personalOrdersRequest: {
        isProcessing: true,
        error: null,
        personalOrdersResponse: null,
    },

};

const personalOrders = (state = initialState, { type, payload }) => {
    switch (type) {
        case personalOrdersActions.SET_SEARCH:
            return {
                ...state,
                filters: payload,
            };
        case personalOrdersActions.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: payload,
            };
        case personalOrdersActions.SET_IS_GET_PERSONAL_ORDERS_REQUEST_PROCESSING:
            return {
                ...state,
                personalOrdersRequest: {
                    ...state.personalOrdersRequest,
                    isProcessing: payload,
                },
            };
        case personalOrdersActions.SET_GET_PERSONAL_ORDERS_REQUEST_ERROR:
            return {
                ...state,
                personalOrdersRequest: {
                    ...state.personalOrdersRequest,
                    error: payload,
                },
            };
        case personalOrdersActions.SET_PERSONAL_ORDERS:
            return {
                ...state,
                personalOrdersRequest: {
                    ...state.personalOrdersRequest,
                    personalOrdersResponse: payload,
                },
            };
        default:
            return state;
    }
};

export default personalOrders;

import * as calendarActions from '@actions/calendar';

const initialState = {
    initialValues: {
        id: null,
        title: '',
        description: '',
        type: '',
        date: new Date(),
        time_from: '',
        time_to: '',
    },
    eventDetails: {
        isOpened: false,
        event: {
            id: null,
            title: '',
            description: '',
            date_from: '',
            date_to: '',
            type: '',
        },
    },
};

const calendar = (state = initialState, { type, payload }) => {
    switch (type) {
        case calendarActions.SET_CALENDAR_VALUES:
            return {
                ...state,
                initialValues: { ...state.initialValues, ...payload },
            };
        case calendarActions.SET_CALENDAR_RESET:
            return {
                ...state,
                initialValues: {
                    title: '',
                    description: '',
                    type: '',
                    date: new Date(),
                    time_from: '',
                    time_to: '',
                },
            };
        case calendarActions.SET_CALENDAR_EVENT_OPEN:
            return {
                ...state,
                eventDetails: {
                    ...state.eventDetails,
                    isOpened: payload,
                },
            };
        case calendarActions.SET_CALENDAR_EVENT_CLOSE:
            return {
                ...state,
                eventDetails: {
                    ...state.eventDetails,
                    isOpened: payload,
                },
            };
        case calendarActions.SET_CALENDAR_EVENT:
            return {
                ...state,
                eventDetails: {
                    ...state.eventDetails,
                    event: payload,
                },
            };
        default:
            return state;
    };
};

export default calendar;
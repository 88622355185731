import qs from 'qs';

import { unexpectedExceptionNotification } from '@components';
import { getCasePageSelector } from '@selectors/case-page-selector';
import { getCasePlanTabSelector } from '@selectors/case-plan-tab-selector';
import axios from '@utils/axios';

const prefix = '@CASE_PLAN_TAB';

export const SET_SEARCH = `${prefix}/SET_SEARCH`;
export const SET_CURRENT_PAGE = `${prefix}/SET_CURRENT_PAGE`;

export const SET_IS_GET_PLANS_REQUEST_PROCESSING = `${prefix}/SET_IS_GET_PLANS_REQUEST_PROCESSING`;
export const SET_GET_PLANS_REQUEST_ERROR = `${prefix}/SET_GET_PLANS_REQUEST_ERROR`;
export const SET_PLANS = `${prefix}/SET_PLANS`;

export const setFiltersAction = filters => ({
    type: SET_SEARCH,
    payload: filters,
});

export const setCurrentPageAction = selectedPage => ({
    type: SET_CURRENT_PAGE,
    payload: selectedPage,
});

/*GET PERSONAL PLANS*/
const setIsGetPlansRequestProcessingAction = isProcessing => ({
    type: SET_IS_GET_PLANS_REQUEST_PROCESSING,
    payload: isProcessing,
});

const setGetPlansRequestErrorAction = error => ({
    type: SET_GET_PLANS_REQUEST_ERROR,
    payload: error,
});

const setPlansAction = data => ({
    type: SET_PLANS,
    payload: data,
});

export const getPlanAction = () => async (dispatch, getState) => {
    dispatch(setIsGetPlansRequestProcessingAction(true));
    try {
        const state = getState();
        const { caseId } = getCasePageSelector(state);
        const { currentPage } = getCasePlanTabSelector(state);
        const queryParams = qs.stringify({ per_page: 10, page: currentPage + 1 });

        const { data } = await axios(`/api/legal_cases/${caseId}/plan_events?${queryParams}`);

        dispatch(setPlansAction(data));
    } catch (error) {
        dispatch(setGetPlansRequestErrorAction(error));
        unexpectedExceptionNotification();
    }
    dispatch(setIsGetPlansRequestProcessingAction(false));
};

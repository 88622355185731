const initialState = {
    basicData: {},
    blockMyData: {},
    blockMyDocument: {},
    myAnotherDocumentForm: {},
    ordersData: {},
    orderResponseData: {},
    counters: {},
};

const personal = (state = initialState, action) => {
    switch (action.type) {
        case 'PERSONAL_STATE':
            return {
                ...state,
                basicData: action.payload,
            };
        case 'ORDER_STATE':
            return {
                ...state,
                ordersData: action.payload,
            };
        case 'ORDER_RESPONSE_STATE':
            return {
                ...state,
                orderResponseData: action.payload,
            };
        case 'PERSONAL_COUNTERS':
            return {
                ...state,
                counters: action.payload,
            };
        case 'PERSONAL_MY_DATA_INFORMATION':
            return {
                ...state,
                blockMyData: action.payload['block_personal_data'],
                blockMyDocument: action.payload['block_personal_documents'],
            };
        case 'PERSONAL_MY_DATA_FORM':
            return {
                ...state,
                myDataForm: action.payload['form'],
            };
        case 'PERSONAL_MY_ANOTHER_DOCUMENT_FORM':
            return {
                ...state,
                myAnotherDocumentForm: action.payload['form'],
            };
        default:
            return state;
    }
};

export default personal;

import * as privateChatActions from '@actions/private-chat';

const initialState = {
    caseId: null,
    chatId: null,

    search: '',
    currentPage: undefined,
    lastPage: 1,

    chatFellow: null,
    readMessages: [],
    unreadMessages: [],

    getPrivateChatRequest: {
        isProcessing: false,
        error: null,
    },

    sendMessageRequest: {
        isProcessing: false,
        error: null,
    },
};

const privateChat = (state = initialState, action) => {
    switch (action.type) {
        case privateChatActions.SET_DATA: {
            const { caseId, chatId, read_messages: readMessages, unread_messages: unreadMessages, chat_fellow: chatFellow } = action.payload;
            const { current_page: currentPage, last_page: lastPage } = readMessages;

            return {
                ...state,
                caseId,
                chatId,
                chatFellow,
                currentPage,
                lastPage,
                readMessages: [...readMessages.data.filter(({ id }) => !state.readMessages.find(message => message.id === id)), ...state.readMessages],
                unreadMessages,
            };
        }
        case privateChatActions.SET_IS_GET_PRIVATE_CHAT_REQUEST_PROCESSING: {
            return {
                ...state,
                getPrivateChatRequest: {
                    ...state.getPrivateChatRequest,
                    isProcessing: action.payload,
                },
            };
        }
        case privateChatActions.SET_GET_PRIVATE_CHAT_REQUEST_ERROR: {
            return {
                ...state,
                getPrivateChatRequest: {
                    ...state.getPrivateChatRequest,
                    error: action.payload,
                },
            };
        }
        case privateChatActions.READ_MESSAGES: {
            const readMessages = state.unreadMessages.filter(({ id }) => action.payload.includes(id));
            const unreadMessages = state.unreadMessages.filter(({ id }) => !action.payload.includes(id));

            return {
                ...state,
                readMessages: [...state.readMessages, ...readMessages],
                unreadMessages,
            };
        }
        case privateChatActions.SET_IS_SEND_MESSAGE_REQUEST_PROCESSING: {
            return {
                ...state,
                sendMessageRequest: {
                    ...state.sendMessageRequest,
                    isProcessing: action.payload,
                },
            };
        }
        case privateChatActions.SET_SEND_MESSAGE_REQUEST_ERROR: {
            return {
                ...state,
                sendMessageRequest: {
                    ...state.sendMessageRequest,
                    error: action.payload,
                },
            };
        }
        case privateChatActions.SEND_MESSAGE: {
            return {
                ...state,
                readMessages: [...state.readMessages, ...state.unreadMessages, action.payload],
                unreadMessages: [],
            };
        }
        case privateChatActions.ADD_NEW_UNREAD_MESSAGE: {
            if (!state.readMessages.find(({ id }) => id === action.payload.id) && !state.unreadMessages.find(({ id }) => id === action.payload.id)) {
                return {
                    ...state,
                    unreadMessages: [...state.unreadMessages, action.payload],
                };
            }

            return state;
        }
        // Кейс когда из другой вкладки приходит наше сообщение в вебсокетах
        case privateChatActions.ADD_NEW_READ_MESSAGE: {
            if (!state.readMessages.find(({ id }) => id === action.payload.id) && !state.unreadMessages.find(({ id }) => id === action.payload.id)) {
                return {
                    ...state,
                    readMessages: [...state.readMessages, action.payload],
                };
            }

            return state;
        }
        // Нужно в любом случае пометить сообщения прочитанными и перенести прочитанные из непрочитанных, если такие есть
        case privateChatActions.SET_MESSAGES_READ: {
            return {
                ...state,
                readMessages: [
                    // Помечаем нами прочитанные сообщения - прочитанными
                    ...state.readMessages.map(message => ({
                        ...message,
                        is_another_user_read: message.is_another_user_read || action.payload.includes(message.id),
                    })),
                    // Добавляем прочитанные сообщения из непрочитанных
                    ...state.unreadMessages
                        .filter(({ id }) => action.payload.includes(id))
                        .map(message => ({
                            ...message,
                            is_another_user_read: message.is_another_user_read || action.payload.includes(message.id),
                        })),
                ],
                // Убираем прочитанные сообщения из непрочитанных
                unreadMessages: state.unreadMessages.filter(({ id }) => !action.payload.includes(id)),
            };
        }
        case privateChatActions.SET_SEARCH: {
            return {
                ...initialState,
                caseId: state.caseId,
                chatId: state.chatId,
                search: action.payload,
            };
        }
        case privateChatActions.RESET: {
            return { ...initialState };
        }
        default:
            return state;
    }
};

export default privateChat;

import * as privateChatsActions from '@actions/private-chats';

const initialState = {
    selectedChatId: null,
    chats: [],

    getPrivateChatsRequest: {
        isProcessing: false,
        error: null,
    },
};

const privateChatList = (state = initialState, action) => {
    switch (action.type) {
        case privateChatsActions.SELECT_PRIVATE_CHAT: {
            return {
                ...state,
                selectedChatId: action.payload,
            };
        }
        case privateChatsActions.SET_IS_GET_PRIVATE_CHATS_REQUEST_PROCESSING: {
            return {
                ...state,
                getPrivateChatsRequest: {
                    ...state.getPrivateChatsRequest,
                    isProcessing: action.payload,
                },
            };
        }
        case privateChatsActions.SET_GET_PRIVATE_CHATS_REQUEST_ERROR: {
            return {
                ...state,
                getPrivateChatsRequest: {
                    ...state.getPrivateChatsRequest,
                    error: action.payload,
                },
            };
        }
        case privateChatsActions.SET_PRIVATE_CHATS: {
            return {
                ...state,
                chats: action.payload,
            };
        }
        case privateChatsActions.CHANGE_LAST_MESSAGE_AND_COUNT: {
            const { currentUserId, message } = action.payload;

            return {
                ...state,
                chats: state.chats.map(chat => {
                    if (chat.id === message.legal_case_chat_id) {
                        return {
                            ...chat,
                            last_message: message,
                            number_of_unread_messages: currentUserId === message.user.id ? chat.number_of_unread_messages : chat.number_of_unread_messages + 1,
                        };
                    }

                    return chat;
                }),
            };
        }
        case privateChatsActions.REMOVE_READ_MESSAGES_FROM_COUNT: {
            const { chatId, readMessagesCount } = action.payload;

            return {
                ...state,
                chats: state.chats.map(chat => {
                    if (chat.id === chatId) {
                        const unreadMessageCount = chat.number_of_unread_messages - readMessagesCount;

                        return {
                            ...chat,
                            number_of_unread_messages: unreadMessageCount >= 0 ? unreadMessageCount : 0,
                        };
                    }

                    return chat;
                }),
            };
        }
        default:
            return state;
    }
};

export default privateChatList;

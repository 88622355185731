const initialState = {};

export default function reducers(state = initialState, action) {
    switch (action.type) {
        case 'SET_DATA':
            return {
                ...action.payload,
            };
        case 'CLEAR_DATA':
            const newState = { ...state };

            delete newState[action.payload];

            return newState;
        default:
            return state;
    }
}

import * as tabActions from '@actions/personal-tasks';

const initialState = {

    filters: null,
    currentPage: 0,

    personalTasksRequest: {
        isProcessing: true,
        error: null,
        personalTasksResponse: null,
    },

};

const personalTasks = (state = initialState, { type, payload }) => {
    switch (type) {
        case tabActions.SET_SEARCH:
            return {
                ...state,
                filters: payload,
            };
        case tabActions.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: payload,
            };
        case tabActions.SET_IS_GET_PERSONAL_TASK_REQUEST_PROCESSING:
            return {
                ...state,
                personalTasksRequest: {
                    ...state.personalTasksRequest,
                    isProcessing: payload,
                },
            };
        case tabActions.SET_GET_PERSONAL_TASK_REQUEST_ERROR:
            return {
                ...state,
                personalTasksRequest: {
                    ...state.personalTasksRequest,
                    error: payload,
                },
            };
        case tabActions.SET_PERSONAL_TASK:
            return {
                ...state,
                personalTasksRequest: {
                    ...state.personalTasksRequest,
                    personalTasksResponse: payload,
                },
            };
        default:
            return state;
    }
};

export default personalTasks;

import Head from 'next/head';
import React from 'react';
import ReactNotification from 'react-notifications-component';
import { Provider } from 'react-redux';

import { updateCurrentUserAction } from '@actions/auth/updateCurrentUser';
import { setSsrData } from '@actions/ssr';
import { AdaptiveProvider, GlobalSpinner } from '@components';
import { WebsocketWatcher } from '@components';

import { SiteLayout } from '../layouts';
import { withReduxStore } from '../store';

import 'uikit/dist/css/uikit.min.css';
import 'react-notifications-component/dist/theme.css';
import './normalize.scss';
import './app.scss';

if (process.browser) {
    window.Pusher = require('pusher-js');

    const logError = console.error;

    console.error = (...error) => {
        // FCK MTRL-RCT-TBS
        if (error[3] === '"react-tabs-0"') {
            console.warn(...error.slice(0, 4));

            return;
        }
        logError(...error);
    };
}

const App = ({ Component, pageProps, store }) => {
    const { component: LayoutComponent = SiteLayout, ...layoutProps } = Component.layout;

    React.useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');

        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    return (
        <Provider store={store}>
            <Head>
                <title>Юридический сервис</title>
            </Head>
            <ReactNotification />
            <AdaptiveProvider>
                <GlobalSpinner />
                <LayoutComponent {...layoutProps}>
                    <Component {...pageProps} />
                </LayoutComponent>
                <WebsocketWatcher />
            </AdaptiveProvider>
        </Provider>
    );
};

App.getInitialProps = ({ ctx: { query, res, store } }) => {
    /** На сервере при первом запросе клиента, сохраняем в redux конфиг с сервера и query параметры */
    if (typeof window === 'undefined') {
        if (res?.currentUser) {
            store.dispatch(updateCurrentUserAction(res?.currentUser));
        }
        store.dispatch(setSsrData(res?.ssrData));
    }
};

//export default withReduxStore(App);
export default withReduxStore(App);

const initialState = {
    headerTitle: '',
    personDay: {},
    sort: {},
    specialists: {},
    filterForm: {},
    quantitySpecialist: '',
};

const specialist = (state = initialState, action) => {
    switch (action.type) {
        case 'SPECIALIST_STATE' :
            return {
                ...state,
                headerTitle: action.payload.title,
                personDay: action.payload['person_day'],
                sort: action.payload.sort,
                specialists: action.payload.specialists,
                filterForm: action.payload.filter,
            };
        case 'SET_SPECIALIST_QUANTITY':
            return {
                ...state,
                quantitySpecialist: action.payload.total
            };
        default:
            return state;
    }
};

export default specialist;

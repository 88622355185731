import { combineReducers } from 'redux';

import admin from './admin/admin';
import core from './admin/core';
import currentUser from './auth';
import calendar from './calendar';
import casePage from './case-page';
import casePlanTab from './case-plan-tab';
import generalChat from './general-chat';
import globalError from './global-error';
import globalSpinner from './global-spinner';
import notifications from './notifications';
import page from './page';
import personal from './personal';
import personalOrders from './personal-orders';
import personalTasks from './personal-tasks';
import privateChat from './private-chat';
import privateChats from './private-chats';
import privateOrderChat from './private-order-chat';
import signUp from './sign-up';
import specialist from './specialist';
import ssr from './ssr';
import tabDocuments from './tab-documents';
import usersOnline from './users-online';

export default combineReducers({
    admin,
    core,
    currentUser,
    signUp,
    page,
    personal,
    specialist,
    ssr,
    globalSpinner,
    privateChat,
    privateChats,
    generalChat,
    privateOrderChat,
    personalOrders,
    globalError,
    personalTasks,
    tabDocuments,
    casePage,
    casePlanTab,
    usersOnline,
    notifications,
    calendar,
});

import qs from 'qs';

import { unexpectedExceptionNotification } from '@components';
import { getPersonalTasksSelector } from '@selectors/personal-tasks-selectors';
import axios from '@utils/axios';

const prefix = '@PERSONAL_TASK';

export const SET_SEARCH = `${prefix}/SET_SEARCH`;
export const SET_CURRENT_PAGE = `${prefix}/SET_CURRENT_PAGE`;

export const SET_IS_GET_PERSONAL_TASK_REQUEST_PROCESSING = `${prefix}/SET_IS_GET_PERSONAL_TASK_REQUEST_PROCESSING`;
export const SET_GET_PERSONAL_TASK_REQUEST_ERROR = `${prefix}/SET_GET_PERSONAL_TASK_REQUEST_ERROR`;
export const SET_PERSONAL_TASK = `${prefix}/SET_PERSONAL_TASK`;

export const setFiltersAction = filters => ({
    type: SET_SEARCH,
    payload: filters,
});

export const setCurrentPageAction = selectedPage => ({
    type: SET_CURRENT_PAGE,
    payload: selectedPage,
});

/*GET PERSONAL TASK*/
export const setIsGetPersonalTasksRequestProcessing = isProcessing => ({
    type: SET_IS_GET_PERSONAL_TASK_REQUEST_PROCESSING,
    payload: isProcessing,
});

export const setGetPersonalTaskRequestError = error => ({
    type: SET_GET_PERSONAL_TASK_REQUEST_ERROR,
    payload: error,
});

export const setPersonalTasks = personalTasks => ({
    type: SET_PERSONAL_TASK,
    payload: personalTasks,
});

export const getPersonalTask = () => async (dispatch, getState) => {
    dispatch(setIsGetPersonalTasksRequestProcessing(true));
    try {
        const { filters, currentPage } = getPersonalTasksSelector(getState());

        const queryParams = qs.stringify({ ...filters, page: currentPage + 1 });

        const { data } = await axios.get(`/api/legal_cases?${queryParams}`);

        dispatch(setPersonalTasks(data));
    } catch (error) {
        dispatch(setGetPersonalTaskRequestError(error));
        unexpectedExceptionNotification();
    }
    dispatch(setIsGetPersonalTasksRequestProcessing(false));
};

const initialState = {
    spinners: [],
};

const globalSpinner = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_SPINNER':
            return { spinners: [...state.spinners, action.payload] };
        case 'HIDE_SPINNER':
            return { spinners: [...state.spinners].filter((spinner) => spinner !== action.payload) };
        default:
            return state;
    }
};

export default globalSpinner;

import * as tabActions from '@actions/tab-documents';

const initialState = {
    selectedDocumentId: null,

    filters: null,
    currentPage: 0,
    lastPage: null,

    documentsRequest: {
        isProcessing: true,
        error: null,
        response: null,
    },

    formForEditDocumentRequest: {
        isProcessing: true,
        response: null,
        error: null,
    },
};

const tabDocuments = (state = initialState, { type, payload }) => {
    switch (type) {
        case tabActions.SET_SELECTED_DOCUMENT_ID:
            return {
                ...state,
                selectedDocumentId: payload,
            };
        case tabActions.SET_SEARCH:
            return {
                ...state,
                filters: payload,
            };
        case tabActions.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: payload,
            };
        case tabActions.SET_IS_GET_DOCUMENTS_REQUEST_PROCESSING:
            return {
                ...state,
                documentsRequest: {
                    ...state.documentsRequest,
                    isProcessing: payload,
                },
            };
        case tabActions.SET_GET_DOCUMENTS_REQUEST_ERROR:
            return {
                ...state,
                documentsRequest: {
                    ...state.documentsRequest,
                    error: payload,
                },
            };
        case tabActions.SET_DOCUMENTS:
            return {
                ...state,
                documentsRequest: {
                    ...state.documentsRequest,
                    response: payload,
                },
            };
        case tabActions.SET_IS_GET_EDIT_DOCUMENT_FORM_REQUEST_PROCESSING:
            return {
                ...state,
                formForEditDocumentRequest: {
                    ...state.formForEditDocumentRequest,
                    isProcessing: payload,
                },
            };
        case tabActions.SET_GET_EDIT_DOCUMENT_FORM_REQUEST_ERROR:
            return {
                ...state,
                formForEditDocumentRequest: {
                    ...state.formForEditDocumentRequest,
                    error: payload,
                },
            };
        case tabActions.SET_EDIT_DOCUMENT_FORM:
            return {
                ...state,
                formForEditDocumentRequest: {
                    ...state.formForEditDocumentRequest,
                    response: payload,
                },
            };
        default:
            return state;
    }
};

export default tabDocuments;

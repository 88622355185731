import * as notificationActions from '@actions/notifications';

const initialState = {
    filters: null,
    currentPage: 0,
    isNotificationOpened: false,
    notificationsCounters: {
        count: 0,
        count_unread: 0,
    },
    notificationsRequest: {
        isProcessing: true,
        error: null,
        notificationsResponse: null,
    },
    notifications: [],
};

const notifications = (state = initialState, { type, payload }) => {
    switch (type) {
        case notificationActions.SET_SEARCH:
            return {
                ...state,
                filters: payload,
            };
        case notificationActions.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: payload,
            };
        case notificationActions.SET_IS_GET_NOTIFICATION_REQUEST_PROCESSING:
            return {
                ...state,
                notificationsRequest: {
                    ...state.notificationsRequest,
                    isProcessing: payload,
                },
            };
        case notificationActions.SET_GET_NOTIFICATION_REQUEST_ERROR:
            return {
                ...state,
                notificationsRequest: {
                    ...state.notificationsRequest,
                    error: payload,
                },
            };
        case notificationActions.SET_NOTIFICATION:
            return {
                ...state,
                notificationsRequest: {
                    ...state.notificationsRequest,
                    notificationsResponse: payload,
                },
            };
        case notificationActions.SET_COUNT_NOTIFICATION:
            return {
                ...state,
                notificationsCounters: payload,
            };
        case notificationActions.SET_OPEN_NOTIFICATION:
            return {
                ...state,
                isNotificationOpened: payload,
            };
        case notificationActions.SET_CLOSE_NOTIFICATION:
            return {
                ...state,
                isNotificationOpened: payload,
            };
        case notificationActions.SET_PAGINATION_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, ...payload],
            };
        case notificationActions.RESET_PAGINATION_NOTIFICATION:
            return {
                ...state,
                notifications: payload,
            };
        default:
            return state;
    }
};

export default notifications;

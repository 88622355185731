const initialState = {
    code: null,
    info: null,
};

const globalError = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ERROR':
            return action.payload;
        default:
            return state;
    }
};

export default globalError;

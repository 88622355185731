import * as tabActions from '@actions/case-plan-tab';

const initialState = {
    filters: null,
    currentPage: 0,
    planRequest: {
        isProcessing: true,
        response: null,
        error: null,
    },
};

const casePlanTab = (state = initialState, { type, payload }) => {
    switch (type) {
        case tabActions.SET_SEARCH:
            return {
                ...state,
                filters: payload,
            };
        case tabActions.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: payload,
            };
        case tabActions.SET_IS_GET_PLANS_REQUEST_PROCESSING:
            return {
                ...state,
                planRequest: {
                    ...state.planRequest,
                    isProcessing: payload,
                },
            };
        case tabActions.SET_GET_PLANS_REQUEST_ERROR:
            return {
                ...state,
                planRequest: {
                    ...state.planRequest,
                    error: payload,
                },
            };
        case tabActions.SET_PLANS:
            return {
                ...state,
                planRequest: {
                    ...state.planRequest,
                    response: payload,
                },
            };
        default:
            return state;
    }
};

export default casePlanTab;
